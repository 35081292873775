<template>
  <div class="page">
    <van-row class="search">
      <van-col span="24">
        <van-search
          v-model="keyword"
          readonly="true"
          @click-input="searchTask"
          placeholder="请输入搜索关键词"
        ></van-search>
      </van-col>
    </van-row>
    <van-swipe class="banner" :autoplay="5000" indicator-color="white">
      <van-swipe-item>
        <img
          @click="inviter()"
          class="item"
          :src="require('../../../assets/images/seller/sellban-01.jpg')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <img
          @click="help()"
          class="item"
          :src="require('../../../assets/images/seller/sellban-02.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="gather">
      <div class="title">
        特惠拼团 <span class="sub color-s1">人数越多价格越低 ！</span>
      </div>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="businessVal"
          :options="business"
          @change="optionChange"
        />
        <van-dropdown-item
          v-model="orderVal"
          :options="order"
          @change="optionChange"
        />
      </van-dropdown-menu>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveGatherList"
      >
        <template #default>
          <div
            class="item"
            v-for="gather in gatherList"
            :key="gather.code"
            @click="detail(gather.code)"
          >
            <van-row>
              <van-col span="10">
                <div class="image">
                  <van-image radius="3" :src="gather.imagePath"></van-image>
                </div>
              </van-col>
              <van-col span="14">
                <div class="name text-short">{{ gather.name }}</div>
                <van-row class="count">
                  <van-col span="12">已报名：{{ gather.countJoin }}人</van-col>
                  <van-col span="12"
                    >剩余：{{ gather.countTotal - gather.countJoin }}人</van-col
                  >
                </van-row>
                <van-row class="price color-s1">
                  <van-col span="12">原价：{{ gather.priceMax }}元</van-col>
                  <van-col span="12">现价：{{ gather.priceSale }}元</van-col>
                </van-row>
                <div class="tips">
                  拼满{{ gather.countTotal }}人可享受最低价：{{
                    gather.priceMin
                  }}元/人
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="16" class="time"
                ><van-count-down :time="gather.time">
                  <template #default="timeData">
                    <span class="text">距离结束还剩</span>
                    <span class="block">{{ timeData.days }}天</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.hours }}时</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}分</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}秒</span>
                  </template></van-count-down
                ></van-col
              >
              <van-col span="8" class="deposit color-s2"
                >预订金:{{ gather.priceDeposit }}元</van-col
              >
            </van-row>
          </div>
        </template></van-list
      >
    </div>
    <Tabbar active="home" />
  </div>
</template>
<script>
import { Search, Swipe, SwipeItem, Grid, GridItem, DropdownMenu, DropdownItem, List, Image, Tag, Icon, CountDown } from 'vant'
import '../../../assets/icon/font-icon.js'
import Tabbar from '../common/Tabbar.vue'
export default {
  components: {
    Tabbar: Tabbar,
    [Search.name]: Search,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown
  },
  data () {
    return {
      finished: false,
      loginState: 'N',
      gatherList: [],
      page: { current: 0, size: 10, last: 1 },
      type: 'RCT',
      business: [
        { text: '全部拼团', value: '' },
        { text: '工作招聘', value: 'RCT' },
        { text: '证书培训', value: 'TRA' }
      ],
      businessVal: '',
      order: [
        { text: '默认排序', value: '' },
        { text: '时间排序', value: 'time' }
      ],
      orderVal: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.retrieveGatherList()
    },
    searchTask () {
      this.$router.push({ path: '/search/home', query: { keyword: this.keyword, type: this.active } })
    },
    optionChange (val) {
      this.page.current = 0
      this.gatherList = []
      this.finished = false
      this.retrieveGatherList()
    },
    async retrieveGatherList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { businessType: this.businessVal, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/promote/gather/retrieveAllGatherList', this.$qs.stringify(pd))
        if (res.status === '200') {
          console.log(res.data)
          this.gatherList = this.gatherList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
      }
    },
    detail (code) {
      var key = window.const.global.PROMOTE_GATHER_DETAIL_BACK
      window.sessionStorage.setItem(key, window.location.href)
      this.$router.push({ path: '/promote/gatherDetail', query: { gatherCode: code } })
    },
    recruit () {
      this.$router.push({ path: '/recruit/home' })
    },
    train () {
      this.$router.push({ path: '/train/home' })
    },
    enroll () {
      this.$router.push({ path: '/enroll/home' })
    },
    love () {
      this.$router.push({ path: '/love/home' })
    },
    focusWP () {
      window.location.href = 'https://mp.weixin.qq.com/s/39sE3Wn97YQb3do316DmbQ'
    },
    search () {
      this.$router.push({ path: '/search/home', query: { type: this.type } })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  height: 100%;
  background-color: #eee;
  // background: linear-gradient(to bottom, #00cc99, #7bfada);
  .banner {
    margin: 0px auto;
    .item {
      color: #fff;
      font-size: 20px;
      line-height: 80px;
      width: 100%;
      text-align: center;
      background-color: #39a9ed;
    }
  }
  ::v-deep .gather {
    width: 98%;
    margin: 1px auto;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 60px;
    .van-dropdown-menu__bar {
      height: 35px;
      box-shadow: 0 0 1vw #eee;
    }
    .title {
      text-align: left;
      padding: 15px 10px 10px 10px;
      font-size: 15px;
      font-weight: 700;
      margin-top: 10px;
      border-bottom: 1px solid #eee;
      .sub {
        margin-left: 10px;
        font-size: 13px;
        font-weight: 400;
      }
    }
    .item {
      margin: 3px;
      border-radius: 3px;
      padding: 3px 0px;
      border-bottom: 1px dashed #eee;
      .name {
        padding: 0px 3px;
        text-align: left;
        font-size: 14px;
        font-weight: 700;
        margin: 3px 0px 5px 0px;
      }
      .count {
        padding: 0px 3px;
        text-align: left;
        margin: 5px 0px;
      }
      .price {
        padding: 0px 3px;
        text-align: left;
        margin: 5px 0px;
        font-weight: 700;
      }
      .tips {
        margin: 0px 3px;
        text-align: center;
        background-color: #ff9966;
        color: #fff;
        border-radius: 2px;
      }
      .time {
        text-align: left;
        margin: 2px 0px;
        .colon {
          display: inline-block;
          margin: 0 1px;
          color: #ff554d;
        }
        .text {
          display: inline-block;
          color: #fff;
          font-size: 12px;
          text-align: center;
          background-color: #ff554d;
          margin-right: 5px;
          padding: 0px 5px;
          border-radius: 2px;
        }
        .block {
          display: inline-block;
          width: 30px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          background-color: #ff554d;
          border-radius: 2px;
        }
      }
      .deposit {
        margin: 2px 0px;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #ff554d;
        border-radius: 3px;
      }
    }
  }
  .br {
    border-right: 1px solid #eee;
  }
}
</style>
